import React from "react";
import CCard from "../components/CCard";

const Services = () =>{

    const data = [
        {
            id: 1,
            title: 'Mobile Applications',
            description: 'Crafting cutting-edge mobile experiences using React Native ' +
                'from concept to deployment, ensuring your app reaches both iOS and ' +
                'Android users with seamless performance. Creating ' +
                'visually stunning, user-friendly apps that not only ' +
                'engage users but also meet your business objectives'
        },
        {
            id: 2,
            title: 'Web Applications',
            description: 'Specializing in building dynamic and interactive web applications using React JS ' +
                'from responsive designs to seamless navigation. ' +
                'Developing innovative and scalable React JS web apps which are not just functional but also future ready.'
        }
    ]
    return (
        <section id="services" className="services">
            <div className="container">

                <div className="section-title">
                    <span>My Services</span>
                    <h2>My Services</h2>
                    <p>Bringing Your Vision to Life</p>
                </div>

                <div className="row justify-content-center">
                    {
                        data.map((item)=>(
                     <CCard title={item.title} description={item.description} />
                    ))}
                </div>

            </div>
        </section>
    )
}

export default Services

import React from "react";

const CCard = (props) =>{
    return (
        <div className="col-md-6 col-lg-4 d-flex align-items-stretch mb-5 mb-lg-0">
            <div className="icon-box">
                <div className="icon"><i className="bx bxl-dribbble"></i></div>
                <h4 className="title"><a href="">{props.title}</a></h4>
                <p className="description">{props.description}</p>
            </div>
        </div>
    )
}

export default CCard

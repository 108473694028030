import React, {useState} from "react"
import {Alert} from "bootstrap";
import {toast,ToastContainer} from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const ContactMe = () => {

    const [userData, setUserData] = useState({
        firstName: '',
        email: '',
        subject: '',
        message: '',
    });
    const [show, setShow] = useState(false);


    const submitData = async (e) =>{
        e.preventDefault();
        const { firstName, email, subject, message } = userData;

        if(firstName && email && subject && message){
            const res = await fetch('https://my-website-b9316-default-rtdb.firebaseio.com/userDataRecords.json', {
                method: 'POST',
                headers: {
                    'Content-Type' : 'application/json',
                },
                body: JSON.stringify({
                    firstName,
                    email,
                    subject,
                    message
                })
            })
            if(res){
                setUserData({
                    firstName: '',
                    email: '',
                    subject: '',
                    message: '',
                })
                toast.success("Thank you for contacting", {
                    position: toast.POSITION.TOP_RIGHT
                });
            } else {
                toast.warn("Please fill in all the fields", {
                    position: toast.POSITION.TOP_RIGHT
                });
            }
        } else {
            toast.warn("Please fill in all the fields", {
                position: toast.POSITION.TOP_RIGHT
            });
        }
    }

    const postUserData = (e) =>{
        let name = e.target.name
       let value = e.target.value
        setUserData({ ...userData, [name]: value})
    }


    return (
        <section id="contact" className="contact">
            <ToastContainer />
            <div className="container">

                <div className="section-title">
                    <span>Contact Me</span>
                    <h2>Contact Me</h2>
                    <p>Let's connect! Drop me a message in a digital bottle, and let's sail through the waves of ideas together</p>
                </div>

                <div className="row">

                    <div className="col-lg-6">

                        <div className="row">
                            <div className="col-md-12">
                                <div className="info-box">
                                    <i className="bx bx-share-alt"></i>
                                    <h3>Social Profiles</h3>
                                    <div className="social-links">
                                        <a href="https://twitter.com/meshamanth" className="twitter" target='blank'><i className="bi bi-twitter"></i></a>
                                        <a href="https://www.instagram.com/shamanth.kr/" className="instagram" target='blank'><i className="bi bi-instagram"></i></a>
                                        <a href="https://www.linkedin.com/in/shamanthkr/" className="linkedin" target='blank'><i className="bi bi-linkedin"></i></a>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="info-box mt-4">
                                    <i className="bx bx-envelope"></i>
                                    <h3>Email Me</h3>
                                    <p>shamanth.kr@gmail.com</p>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="info-box mt-4">
                                    <i className="bx bx-phone-call"></i>
                                    <h3>Call Me</h3>
                                    <p>+91 99808 58855</p>
                                </div>
                            </div>
                        </div>

                    </div>

                    <div className="col-lg-6">
                        <form action="forms/contact.php" method="post" role="form" className="php-email-form">
                            <div className="row">
                                <div className="col-md-6 form-group">
                                    <input
                                        type="text"
                                        name="firstName"
                                        className="form-control"
                                        placeholder="Your Name"
                                        value={userData.firstName}
                                        onChange={postUserData}
                                        />
                                </div>
                                <div className="col-md-6 form-group mt-3 mt-md-0">
                                    <input
                                        type="email"
                                        className="form-control"
                                        name="email"
                                        placeholder="Your Email"
                                        value={userData.email}
                                        onChange={postUserData}
                                        />
                                </div>
                            </div>
                            <div className="form-group mt-3">
                                <input
                                    type="text"
                                    className="form-control"
                                    name="subject"
                                    placeholder="Subject"
                                    value={userData.subject}
                                    onChange={postUserData}
                                    />
                            </div>
                            <div className="form-group mt-3">
                                <textarea
                                    className="form-control"
                                    name="message"
                                    rows="5"
                                    placeholder="Message"
                                    value={userData.message}
                                    onChange={postUserData}
                                    />
                            </div>
                            <div className="text-center" style={{
                                paddingTop: 20
                            }}>
                                <button type="submit" onClick={submitData}>Send Message</button>
                            </div>
                        </form>
                    </div>

                </div>

            </div>
        </section>
    )
}

export default ContactMe

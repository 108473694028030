import logo from './logo.svg';
import './App.css';
import Header from "./components/Header"
import Footer from "./components/Footer"
import HomeScreen from "./Pages/HomeScreen"
import ContactMe from "./Pages/ContactMe";
import BackToTopButton from "./components/BackToTop"
import "./assets/css/style.css"
import Resume from "./Pages/Resume";
import Services from "./Pages/Services";

function App() {
  return (
      <div>
    <Header />
          <HomeScreen/>
          <Resume />
          <Services />
          <ContactMe />
          <Footer />
          <BackToTopButton />
      </div>
  );
}

export default App;

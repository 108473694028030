import React from 'react';

const HomeScreen = () =>{
    return(
    <section id="hero">
        <div className="hero-container">
            <h1>Shamanth</h1>
            <h2>I'm UI/UX Developer with React Native and React.js Expertise</h2>
        </div>
    </section>
    )
}

export default HomeScreen;

import React from "react"

const Resume = () =>{
    return (
        <section id="resume" className="resume">
            <div className="container">

                <div className="section-title">
                    <span>My Resume</span>
                    <h2>My Resume</h2>
                    <p>Unlocking Opportunities: My Professional Journey</p>
                </div>

                <div className="row">
                    <div className="col-lg-6">
                        <h3 className="resume-title">Summary</h3>
                        <div className="resume-item pb-0">
                            <h4>Shamanth KR</h4>
                            <p><em>Innovative and deadline-driven UI/UX Developer with 3+ years of experience designing
                                and developing user-centered websites and smartphone applications from initial concept to
                                final, polished deliverable.</em></p>
                            <p>
                                <ul>
                                    <li>Bengaluru, Karnataka, IN</li>
                                    <li>+91 99808 58855 </li>
                                    <li>shamanth.kr@gmail.com</li>
                                </ul>
                            </p>
                        </div>

                        <h3 className="resume-title">Education</h3>
                        <div className="resume-item">
                            <h4>Bachelor of Engineering (BE) </h4>
                            <h5>2012 - 2016</h5>
                            <p><em>K.L.E College of Engineering and Technology, Chikodi (V.T.U)</em></p>
                            <p>Fuelled by passion for innovation, I hold a  BE degree from Visvesvaraya Technological University (V.T.U),
                                a testament to my commitment to technical excellence and problem-solving prowess.</p>
                        </div>
                    </div>
                    <div className="col-lg-6">
                        <h3 className="resume-title">Professional Experience</h3>
                        <div className="resume-item">
                            <h4>Project Lead and Senior Software Engineer</h4>
                            <h5>2021 - Present</h5>
                            <p><em>Ijona Technologies, Bengaluru, Karnataka, IN</em></p>
                            <p>
                                <ul>
                                    <li>Lead in the design, development, and deployment of high quality web and mobile applications.
                                    </li>
                                    <li>Lead the development and implementation of complex software features,
                                        ensuring adherence to coding standards and best practices.
                                    </li>
                                    <li>Work closely with product managers and designers to define and
                                        prioritize software requirements and user stories.
                                    </li>
                                    <li>Actively participate in Agile development methodologies,
                                        including daily stand-ups, sprint planning, and retrospectives.
                                    </li>
                                </ul>
                            </p>
                        </div>
                        <div className="resume-item">
                            <h4>Software Engineer</h4>
                            <h5>2019 - 2021</h5>
                            <p><em>Walkin Software Technolgies, Bengaluru, Karnataka, IN</em></p>
                            <p>
                                <ul>
                                    <li>Developed and maintained web and mobile applications using React.js and React Native.
                                    </li>
                                    <li>Implemented responsive designs and optimized applications for performance and scalability.</li>
                                    <li>Conducted unit testing and integration testing to ensure the quality and reliability of software applications.
                                    </li>
                                    <li>Assisted in the deployment and configuration of applications on various platforms
                                    </li>
                                </ul>
                            </p>
                        </div>
                    </div>
                </div>

            </div>
        </section>
    )
}

export default Resume

import React from "react";

const Footer = () =>{
    return (
        <div>
            <footer id="footer">
            <div className="container">
                <h3>Shamanth KR</h3>
                <p>Transforming Ideas into Seamless Web and Mobile Experiences</p>
                {/*<div className="social-links">*/}
                {/*    <a href="#" className="twitter"><i className="bx bxl-twitter"></i></a>*/}
                {/*    <a href="#" className="facebook"><i className="bx bxl-facebook"></i></a>*/}
                {/*    <a href="#" className="instagram"><i className="bx bxl-instagram"></i></a>*/}
                {/*    <a href="#" className="google-plus"><i className="bx bxl-skype"></i></a>*/}
                {/*    <a href="#" className="linkedin"><i className="bx bxl-linkedin"></i></a>*/}
                {/*</div>*/}
                <div className="copyright">
                    &copy; Copyright <strong><span>Shamanth</span></strong>. All Rights Reserved
                </div>
            </div>
        </footer>
        </div>

    )
}

export default Footer

import { useState, useEffect } from 'react';
import Offcanvas from 'react-bootstrap/Offcanvas';

function OffCanvasExample({ name, ...props }) {
    const [show, setShow] = useState(false);
    const [showButton, setShowButton] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    useEffect(() => {
        const handleScroll = () => {
            // If the user has scrolled down 100 pixels or more, show the button, otherwise hide it
            if (window.scrollY > 100) {
                setShowButton(true);
            } else {
                setShowButton(false);
            }
        };

        // Attach the scroll event listener
        window.addEventListener('scroll', handleScroll);

        // Clean up the event listener when the component unmounts
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    return (
        <>
            <div className='toggle-icon-container' style={{
                backgroundColor: showButton && 'rgba(36, 41, 46, 0.6)',
            }}>
            <i className="bi bi-list mobile-nav-toggle" onClick={handleShow} ></i>
            </div>
            <Offcanvas show={show} onHide={handleClose} {...props} style={{height: '50%'}}>
                <nav id="navbar" className="navbar-phone">
                    <ul>
                        <li><a className="nav-link  active" href="#hero" onClick={handleClose}>Home</a></li>
                        <li><a className="nav-link " href="#about" onClick={handleClose}>About</a></li>
                        <li><a className="nav-link " href="#resume" onClick={handleClose}>Resume</a></li>
                        <li><a className="nav-link " href="#services" onClick={handleClose}>Services</a></li>
                        <li><a className="nav-link  " href="#portfolio" onClick={handleClose}>Portfolio</a></li>
                        <li><a className="nav-link s" href="#contact" onClick={handleClose}>Contact</a></li>
                    </ul>
                </nav>
            </Offcanvas>
        </>
    );
}

export default OffCanvasExample

import React, { useState, useEffect } from 'react';

function BackToTopButton() {
    const [showButton, setShowButton] = useState(false);

    // Function to scroll back to the top of the page
    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth',
        });
    };

    // Listen to scroll events
    useEffect(() => {
        const handleScroll = () => {
            // If the user has scrolled down 100 pixels or more, show the button, otherwise hide it
            if (window.scrollY > 100) {
                setShowButton(true);
            } else {
                setShowButton(false);
            }
        };

        // Attach the scroll event listener
        window.addEventListener('scroll', handleScroll);

        // Clean up the event listener when the component unmounts
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    return (
        <div>
            {/* Conditional rendering of the back to top button */}
            {showButton && (
                <a href="" className="back-to-top d-flex align-items-center justify-content-center active"><i
                    className="bi bi-arrow-up-short"></i></a>
            )}
        </div>
    );
}

export default BackToTopButton;

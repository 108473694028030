import React, {useEffect, useState} from "react";
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import '../assets/css/style.css';
import OffCanvasExample from "./OffCanvas"

const CNavbar = () =>{
    const [active, setActive] = useState("Home");

    const handleScroll = () => {
        const scrollY = window.scrollY;
        // Define the positions of the sections on your page
        const sections = {
            Home: 0,
            About: 500,
            Resume:1000,
            Services: 1500,
            Portfolio: 2000,
            Contact: 2500,
        };

        // Determine which section is in view based on scroll position
        let activeSection = "Home";
        for (const section in sections) {
            if (scrollY >= sections[section]) {
                activeSection = section;
            }
        }

        // Update the active state
        setActive(activeSection);
    };

    useEffect(() => {
        window.addEventListener("scroll", handleScroll);
        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    }, []);

    return (
        <div>
        <nav id="navbar" className="navbar">
            <ul>
                <li>
                    <a className={active === 'Home' ? "nav-link scrollto active" : 'nav-link scrollto'} href="#"
                    onClick={()=>setActive('Home')}
                    >
                        Home
                    </a>
                </li>
                <li>
                    <a className={active === 'About' ? "nav-link scrollto active" : 'nav-link scrollto'} href="#about"
                       onClick={()=>setActive('About')}
                    >
                        About</a></li>
                <li><a className={active === 'Resume' ? "nav-link scrollto active" : 'nav-link scrollto'} href="#resume"
                       onClick={()=>setActive('Resume')}
                >Resume</a></li>
                <li><a className={active === 'Services' ? "nav-link scrollto active" : 'nav-link scrollto'} href="#services"
                       onClick={()=>setActive('Services')}
                >Services</a></li>
                <li><a className={active === 'Portfolio' ? "nav-link scrollto active" : 'nav-link scrollto'} href="#portfolio"
                       onClick={()=>setActive('Portfolio')}
                >Portfolio</a></li>
                <li><a className={active === 'Contact' ? "nav-link scrollto active" : 'nav-link scrollto'} href="#contact"
                       onClick={()=>setActive('Contact')}
                >Contact</a></li>
            </ul>
        </nav>
            <div className='mobile-nav-toggle'>
            <OffCanvasExample  key={1} placement={'bottom'} responsive={'lg'}/>
            </div>
        </div>

)}

export default CNavbar;

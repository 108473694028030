import React from "react";
import CNavbar from "./Navbar";
import '../assets/css/style.css'


const Header = () =>{
    return (
        <header id="header" className="fixed-top d-flex justify-content-center align-items-center header-transparent">
        <CNavbar />
        </header>
    )
}

export default Header;

